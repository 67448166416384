import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row, Stack } from "react-bootstrap";

const organizers = [
  {
    imgUrl: "bianca.jpg",
    name: "BIANCA SCÎRTOCEA",
    title: "Fundraising Manager",
    phone: "+40 727 684 594",
    email: "bianca.scirtocea@lsacbucuresti.ro"
  },
  {
    imgUrl: "diana.jpg",
    name: "DIANA PINȚOIU",
    title: "Project Manager",
    phone: "+40 725 217 827",
    email: "diana.pintoiu@lsacbucuresti.ro"
  },
  {
    imgUrl: "alexandra.jpg",
    name: "ALEXANDRA NIOAȚĂ",
    title: "Public Relations Manager",
    phone: "+40 720 372 087",
    email: "alexandra.nioata@lsacbucuresti.ro",
  }
]

function OrganizerCard() {
  return (
    <>
      <Container className="text-center">
        <Row>
          {organizers.map((o, index) => (
            <Col key={index} xs={12} lg={4} className="d-flex justify-content-center my-4">
              <Stack className="card" gap={0}>
                <img src={o.imgUrl} alt="" />
                <h2>{o.name}</h2>
                <h3>{o.title}</h3>
                <p>{o.phone}</p>
                <p>{o.email}</p>
              </Stack>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

function App() {
  return (
    <Container>
      <Row>
        <Col className="coll" xs={12}>
          <img
            style={{ maxWidth: "180px", marginBottom: "24px" }}
            src="logo.png"
            alt="Logo Targ"
          />
        </Col>
        <Col className="coll" xs={12}>
          <img src="palat.png" alt="" />
        </Col>
        <Col className="coll" xs={12}>
          <h1>COMING SOON</h1>
          <h2>15 - 16 march 2025</h2>
        </Col>
        <OrganizerCard />
      </Row>
    </Container>
  );
}

export default App;